:root {
  --bs-primary: #0e2c49;
  --bs-primary-dark: #021a49;
  --bs-secondary: #cc9933;
  --bs-success: #01884e;
  --bs-primary-rgb: 0, 32, 96;
  --bs-secondary-rgb: 0, 110, 197;
  --bs-success-rgb: 1, 136, 78;

  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
}
/* --bs-color-selectors */
