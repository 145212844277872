@import url("root.css");

/* FOOTER */
.footer {
  position: relative;
  bottom: 0;
  width: 100%;

  background-color: var(--bs-primary);
  color: var(--bs-white);
}
