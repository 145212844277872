@import url("root.css");

.bg-gray-200 {
  background-color: var(--bs-gray-200);
}

.bg-gray-500 {
  background-color: var(--bs-gray-500);
}
/*  */

::selection {
  background-color: #cc9933;
  color: #000000;
}

.btn-primary {
  --bs-btn-bg: var(--bs-primary);
  --bs-btn-border-color: var(--bs-primary);
  --bs-btn-hover-bg: var(--bs-primary-dark);
  --bs-btn-hover-border-color: var(--bs-primary-dark);
  --bs-btn-active-bg: var(--bs-primary);
  --bs-btn-active-border-color: var(--bs-primary);
}

.dropdown-item {
  --bs-dropdown-link-active-bg: var(--bs-primary);
}

/* CAROUSEL */
.carousel-item {
  height: 36rem;
  background-color: var(--bs-primary);
  background-position: center;
  background-size: cover;
  position: relative;
}

.bd-placeholder-img {
  width: 100%;
  opacity: 0.15;
}

.carousel-item a {
  background-color: var(--bs-secondary);
  color: var(--bs-white);
}

.carousel-item a:hover {
  background-color: var(--bs-primary-dark);
}

/* SIDE BAR */

.side-bar {
  height: 80vh;
  position: sticky;
  top: 6rem;
  overflow-y: scroll;
}

/* Hide the scrollbar by default */
.side-bar::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
  background-color: #f5f5f5;
  visibility: hidden;
}

.side-bar::-webkit-scrollbar-thumb {
  background-color: var(--bs-primary);
  border-radius: 5px;
  visibility: hidden;
}

/* Show the scrollbar on hover */
.side-bar:hover::-webkit-scrollbar {
  visibility: visible;
}

.side-bar:hover::-webkit-scrollbar-thumb {
  visibility: visible;
}

/*  */

/* POSTS SLIDER & POST GRID */

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 1rem;
}

.slider-container {
  display: flex;
  gap: 15px;
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-behavior: smooth;
  width: 100%;
  height: fit-content;
  padding-bottom: 10px;
  margin-top: 15px;
}

.slider-container::-webkit-scrollbar {
  width: 0.5rem;
  height: 0.5rem;
  background-color: #f5f5f5;
  visibility: hidden;
}

.slider-container::-webkit-scrollbar-thumb {
  background-color: var(--bs-primary);
  border-radius: 5px;
  visibility: hidden;
}

/* Show the scrollbar on hover */
.slider-container:hover::-webkit-scrollbar {
  visibility: visible;
}

.slider-container:hover::-webkit-scrollbar-thumb {
  visibility: visible;
}

.slider-card a {
  text-decoration: none;
  color: var(--bs-gray-800);
}

.slider-card {
  flex: 0 0 22rem; /* set the width of each card */
  margin-right: 10px;
  margin: 0px;
  transition: transform 0.3s ease-in-out;
}

.slider-card:hover {
  transform: scale(1.02);
}

.card-img-top {
  height: 8rem;
  background-color: #d1d0d0;
}

.post-card-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
}

.post-card-title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
}

/*  */

.header_img {
  background-position: top;
  background-repeat: no-repeat;
  height: 20rem;

  background-attachment: fixed;
}

/* TEAM CONTAINERS */

.team-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(355px, 1fr));
  gap: 1rem;
}

.fill-parent {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/*  */

@media (max-width: 1000px) {
  .side-bar {
    display: none;
  }

  .bd-placeholder-img {
    height: 100%;
    width: auto;
  }
}

@media (max-width: 768px) {
}
