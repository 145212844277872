@import url("root.css");

.navbar-brand img {
  width: 45px;
  margin-right: 12px;
}

.navbar-brand span {
  font-weight: 700;
  color: var(--bs-primary);
}
