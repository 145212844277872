@import url("root.css");

.react-calendar {
  max-width: 300px;
  min-width: 250px;
}

.react-calendar__navigation button {
  height: 30px;
  min-width: 30px;
  border: 1px solid #ddd;
  background-color: #fff;
}

.react-calendar__month-view__weekdays {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  padding: 5px;
  border-bottom: 1px solid #ddd;
}

.react-calendar__year-view__months__month {
  font-size: 14px;
  font-weight: bold;
  justify-content: space-between;
  padding: 5px;
  border: 1px solid #ddd;
  background-color: #fff;
}

.react-calendar__month-view__weekdays__weekday {
  flex: 1;
  text-align: center;
}

.react-calendar__month-view__days {
  display: flex;
  flex-wrap: wrap;
}

.react-calendar__month-view__days__day {
  flex: 1;
  height: 45px;
  padding: 5px;
  border: 1px solid #ddd;
  background-color: #fff;
  cursor: pointer;
}

.react-calendar__tile--active {
  background-color: #ddd;
  font-weight: 600;
}

.react-calendar__tile--now {
  background-color: var(--bs-primary);
  color: #ffffff;
  font-weight: 600;
}

.react-calendar__tile--hasActive {
  background-color: var(--bs-primary);
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #bbb;
}

.hasEvent {
  background-color: var(--bs-success);
  color: #fff;
  font-weight: 600;
}
